import axios from "axios";

export async function withdrawMedia(media_no, btcAddress, amount) {
    const url = "https://icetm.kentkart.com/api/withdrawMedia";

    try {
        const response = await axios.post(url, {
            provider_id: "643be86ada784ff54f32dac7",
            media_id: "643cf657107ffe149bb15e8d",
            media_type: "abt",
            media_no: media_no,
            to: btcAddress,
            amount: amount * 100,
        }, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (response.data.code === 0) {
            console.log("withdrawMedia response", response.data.data);
            return response.data.data;
        } else {
            throw new Error(response.data.message);
        }
    } catch (error) {
        console.error("Error executing withdrawMedia:", error);
        throw error;
    }
}
