export class ReturnObj{
    static OK  = 0;
    static WARN  = 100;
    static ERR = 1;

    code;
    message;
    data;

    constructor(code, message, data){
        this.code = code;
        this.message = message;
        this.data = data;
    }
}
