import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, Link } from "react-router-dom";
import { Login } from "./Login";
import loginImage from "../../../_start/assets/images/login-image-warapper.png";
import { ContactUsModal } from "../../../app/modals/ContactUsModal";

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add("bg-white");
    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);
  const [show, setShow] = useState(false);

  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-column flex-lg-row flex-column-fluid" id="kt_login">
        <div className="d-flex flex-column flex-lg-row-auto w-lg-600px pt-15 pt-lg-0 login-image-wrapper"
          style={{
            backgroundImage: `url('${loginImage}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'bottom',
            minHeight: '380px'
          }}
        >
        </div>
        <div className="login-content flex-lg-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden px-10 p-lg-7 mx-auto mw-450px w-100">
          <div className="d-flex flex-column-fluid flex-center py-10">
            <Switch>
              <Route path="/auth/login" component={Login} />
              <Redirect from="/auth" exact={true} to="/auth/login" />
              <Redirect to="/auth/login" />
            </Switch>
          </div>
          <div className="d-flex justify-content-center align-items-center py-7 py-lg-0">
            <a className="text-primary fw-bolder fs-4" onClick={() => setShow(true)}>
              Contact Us
            </a>
          </div>
        </div>
      </div>
      {/* begin::Modals */}
        <ContactUsModal show={show} handleClose={() => setShow(false)} />
      {/* end::Modals */}
    </div>
  );
}
