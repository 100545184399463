import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();
const toastOptions = {
  autoClose: 5000,
  hideProgressBar: true,
  position: "bottom-right"
};

let printerDevice = null;
let printerConnected = false;

export async function connectToPrinter() {
  if (printerConnected) {
    return printerDevice; // If already connected, return the printer object directly
  }

  try {
    const device = await navigator.bluetooth.requestDevice({
      filters: [{ name: 'MTP-3' }],
      optionalServices: ['000018f0-0000-1000-8000-00805f9b34fb']
    });

    const server = await device.gatt.connect();

    printerDevice = {
      device,
      server,
    };

    printerConnected = true;

    return printerDevice;
  } catch (error) {
    console.error('Failed to connect to the printer:', error);
    return null;
  }
}

export function getPrinterDevice() {
  return printerDevice;
}

export function isPrinterConnected() {
  return printerConnected;
}

export async function disconnectToPrinter() {
  if (printerDevice && printerDevice.server) {
    await printerDevice.server.disconnect();
    printerDevice = null;
    printerConnected = false;
    return true;
  } else {
    return null;
  }
}

export async function printReceipt(receiptData) {
  const printer = getPrinterDevice();

  if (!!printer) {

    const { server } = printer;

    try {
      const service = await server?.device?.gatt?.getPrimaryService(
        "000018f0-0000-1000-8000-00805f9b34fb"
      );
      const characteristic = await service?.getCharacteristic(
        "00002af1-0000-1000-8000-00805f9b34fb"
      );

      const chunkSize = 512;
      const textEncoder = new TextEncoder();
      const encodedText = textEncoder.encode(receiptData);
      const chunks = [];
      for (let i = 0; i < encodedText.length; i += chunkSize) {
        chunks.push(encodedText.slice(i, i + chunkSize));
      }

      for (const chunk of chunks) {
        await characteristic?.writeValue(chunk);
      }

      console.log("Receipt printed!");
    } catch (error) {
      console.error("Error printing receipt", error);
    }
  }
}