/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Modal } from "react-bootstrap-v5";
import { KTSVG } from "../../_start/helpers";

type Props = {
  show: boolean;
  handleClose: () => void;
};

const ContactUsModal: React.FC<Props> = ({ show, handleClose }) => {

  return (
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog-centered mw-500px h-auto"
      show={show}
      onHide={handleClose}
    >
    <div className="container px-10 py-10">
      <div className="modal-header py-2 d-flex justify-content-end border-0">
          {/* begin::Close */}
          <div
            className="btn btn-icon btn-sm btn-close"
            onClick={handleClose}
          >
          </div>
          {/* end::Close */}
        </div>
      <div>
        <h1 className="anchor fw-bolder mb-5" id="basic">
          <a href="#basic"></a>CONTACT US
        </h1>

        <div className="py-5 contact-text" style={{ justifyContent : 'center' }}>
        Want to contact us? We’d love to hear from you. Here’s how you can reach us.
        </div>

        <div className="py-5 pl-5">
          <div className="social-icon">
              <i className="fas fa-envelope-open-text fs-1 me-2"></i><a href='mailto:contact@icecorp.com' >contact@icecorp.com </a>          
          </div>
          <div className="social-icon">
            <i className="fab fa-linkedin fs-1 me-2"></i><a href='https://www.linkedin.com/company/icecorp' target="_blank">https://www.linkedin.com/company/icecorp</a>
            
          </div>
        </div>
      </div>
      </div>
    </Modal>
  );
};

export { ContactUsModal };
