import { connect } from '@wagmi/core';
import { polygon } from 'wagmi/chains';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { toast, ToastOptions, ToastPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();
const toastOptions: ToastOptions = {
    autoClose: 1000,
    hideProgressBar: true,
    position: "bottom-right" as ToastPosition,
};

export const handleMetaMaskConnection = async () => {
    try {
        await connect({
            connector: new MetaMaskConnector({
                chains: [polygon],
            }),
        });
    }
    catch (error) {
        toast.error('MetaMask connection failed. Please try again.', toastOptions);
    }
};
