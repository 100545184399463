import axios from "axios";
import { constants } from '../resources/constants';

export async function debitProviderMedia(media_no, btcAddress, amount) {
    const url = constants.apiURL + "/debitProviderMedia";

    try {
        const response = await axios.post(url, {
            provider_id: constants.withdrawProviderId,
            sender_address: btcAddress,
            media_no: media_no,
            amount: amount * 100,
        }, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (response.data.code === 0) {
            console.log("debitProviderMedia response", response.data);
            return response.data;
        } else {
            throw new Error(response.data.message);
        }
    } catch (error) {
        console.error("Error executing debitProviderMedia:", error);
        throw error;
    }
}
