/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useTheme } from "../core";
import { ContactUsModal } from "../../../app/modals/ContactUsModal";
import { constants } from "../../../app/resources/constants";
import { getExchangeData } from "../../../app/services";

export function Footer() {
  const { classes } = useTheme();
  const [show, setShow] = useState(false);
  const [currentUsdBuyPrice, setCurrentUsdBuyPrice] = useState('-');
  const [currentBtcBuyPrice, setCurrentBtcBuyPrice] = useState('-');
  const [currentUsdSellPrice, setCurrentUsdSellPrice] = useState('-');
  const [currentBtcSellPrice, setCurrentBtcSellPrice] = useState('-');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const media_id = constants.withdrawMediaId;
        const provider_id = constants.withdrawProviderId;
        const [usdBuyRes, btcBuyRes, usdSellRes, btcSellRes] = await Promise.all([
          await getExchangeData(
            2000000,
            media_id,
            provider_id,
            constants.withdrawOpType,
            constants.withdrawPayTypeCash
          ),
          await getExchangeData(
            2000000,
            media_id,
            provider_id,
            constants.withdrawOpType,
            constants.withdrawPayTypeBTC
          ),
          await getExchangeData(
            2000000,
            media_id,
            provider_id,
            constants.depositOpType,
            constants.withdrawPayTypeCash
          ),
          await getExchangeData(
            2000000,
            media_id,
            provider_id,
            constants.depositOpType,
            constants.withdrawPayTypeBTC
          ),
        ]);
        setCurrentUsdBuyPrice(usdBuyRes.data.exchange_rate.toFixed(2));
        setCurrentBtcBuyPrice(btcBuyRes.data.exchange_rate.toFixed(2));
        setCurrentUsdSellPrice(usdSellRes.data.exchange_rate.toFixed(2));
        setCurrentBtcSellPrice(btcSellRes.data.exchange_rate.toFixed(2));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    const intervalId = setInterval(fetchData, 30000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className={`footer py-4 d-flex flex-lg-column`} id="kt_footer">
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        <div className="text-dark order-2 order-md-0">
          <span className="text-muted fw-bold me-2">
            {new Date().getFullYear()} &copy;
          </span>
          <a href="#" className="text-gray-800 text-hover-primary">
            Icecorp
          </a>
        </div>
        {/* end::Copyright */}

        <div className="d-flex ">
          <div className="currency">
            <div className="target">
              <span className="currency-txt-BuySell text-bold-2">Buy: </span>
              <span className="currency-txt-usd"> 1 USD : <span className="text-bold-2">{currentUsdBuyPrice} </span>{" "}pts</span>
              <span className="currency-txt-usd"> 1 BTC : <span className="text-bold-2">{currentBtcBuyPrice}</span>{" "}pts</span>
              <hr className="separator" />
              <span className="currency-txt-BuySell text-bold-2">Sell: </span>
              <span className="currency-txt-usd"> 1 USD : <span className="text-bold-2">{currentUsdSellPrice} </span>{" "}pts</span>
              <span className="currency-txt-usd"> 1 BTC : <span className="text-bold-2">{currentBtcSellPrice}</span>{" "}pts</span>
            </div>
          </div>

        </div>

        {/* begin::Nav */}
        <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
          <li className="menu-item">
            <a className="menu-link pe-0 pe-2" onClick={() => setShow(true)}>
              Contact
            </a>
          </li>
        </ul>

        {/* end::Nav */}
      </div>
      {/* end::Container */}
      {/* begin::Modals */}
      <ContactUsModal show={show} handleClose={() => setShow(false)} />
      {/* end::Modals */}
    </div>
  );
}
