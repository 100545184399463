import React, { useState, useEffect } from "react";
import { CardSection } from "../profile/components/CardSection";
import { RegisterPage } from "../register/Register";
import { Transactions } from "../profile/components/Transactions";
import { Deposit } from "../profile/components/Deposit";
import { Withdraw } from "./components/Withdraw";
import { Actions } from "../profile/components/Actions";
import { useLocation } from "react-router-dom";
import { Location } from "history";
import { getMediaInfo, getMediaBalance } from "../../services";
import { usePageData } from "../../../_start/layout/core";
interface LocationState {
  btcAddress: string;
  cardNumber: string;
}

const ProfilePage: React.FC = () => {
  const location = useLocation() as Location<LocationState>;
  const [selectedAction, setSelectedAction] = useState("transactions");
  const [btcAddress, setBtcAddress] = useState(location.state.btcAddress);
  const [cardNumber, setCardNumber] = useState(location.state.cardNumber);
  const [registrationStatus, setRegistrationStatus] = useState(true);
  const [fetchMediaStatus, setFetchMediaStatus] = useState(false);
  const { setBalance } = usePageData();

  const handleActionClick = (action: string) => {
    setSelectedAction(action);
  };

  const getMediaBalanceData = async () => {
    const mediaData = await getMediaBalance(cardNumber);
    if (!!mediaData && mediaData.code === 0) {
      let mediaBalance = parseFloat(
        (mediaData.data.temp_balance / 100).toFixed(2)
      );
      setBalance(mediaBalance);
    }
  };

  useEffect(() => {
    const mediaData = async () => {
      const mediaResp = await getMediaInfo(cardNumber);
      setFetchMediaStatus(!fetchMediaStatus);
      if (!!mediaResp) {
        setBtcAddress(mediaResp.data.wallet_address);
      } else {
        setRegistrationStatus(false);
      }
    };
    mediaData();
    getMediaBalanceData();
  }, [btcAddress, cardNumber]);



  return (
    <div className="profile-body px-10 py-10">
      <div>
        <div className="row">
          <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
            <CardSection btc_address={btcAddress} card_number={cardNumber} getMediaBalanceData={getMediaBalanceData} />
            {!btcAddress && <RegisterPage card_number={cardNumber} />}
          </div>
          <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
            <div className="profile-action-btn">
              <div className="profile-actions">
                <Actions onActionClick={handleActionClick} />
              </div>
            </div>
            <div className="d-flex flex-row">
              {selectedAction === "transactions" && <Transactions />}
              {selectedAction === "withdraw" && (
                <Withdraw
                  btcAddr={btcAddress}
                  cardNum={cardNumber}
                  registrationStatus={registrationStatus}
                  getMediaBalanceData={getMediaBalanceData}
                />
              )}
              {selectedAction === "deposit" && (
                <Deposit
                  btcAddress={btcAddress}
                  cardNumber={cardNumber}
                  registrationStatus={registrationStatus}
                  getMediaBalanceData={getMediaBalanceData}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export { ProfilePage };
