import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../helpers";
import { HeaderUserMenu } from "../../../partials";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { usePageData } from "../../../../_start/layout/core";
import { connectToPrinter, disconnectToPrinter, isPrinterConnected, printReceipt} from '../../../../app/helpers/ReceiptPrinter/printerUtils';
import { toast, ToastOptions, ToastPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();
const toastOptions: ToastOptions = {
    autoClose: 1000,
    hideProgressBar: true,
    position: "bottom-right" as ToastPosition,
};

export function Topbar() {
  const history = useHistory();
  const location = useLocation();
  const [searchButton, setSearchButton] = useState(false);
  const { printerConnectionStatus, setPrinterConnectionStatus } = usePageData();
  const { showReceiptButton, setShowReceiptButton } = usePageData();
  const lastReceipt = localStorage.getItem('lastReceipt');

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname == "/profile") {
      setSearchButton(true);
      if(!!lastReceipt) {
        setShowReceiptButton(true);
      }
    } 
  }, [location.pathname]);

  useEffect(() => {
    const printerConnected = isPrinterConnected();
    if(printerConnected) {
      setPrinterConnectionStatus('connected');
    }
  }, [printerConnectionStatus]);

  const connectPrinter = async () => {
    const connectPrinterRes = await connectToPrinter();
    if(!!connectPrinterRes) {
      setPrinterConnectionStatus('connected');
    }
  };
  const disconnecPrinter = async () => {
    const disconnectPrinterRes = await disconnectToPrinter();
    if(disconnectPrinterRes) {
      setPrinterConnectionStatus('disconnected');
    }
  };

  const reprintReceipt = async () => {
    const receiptData = localStorage.getItem('lastReceipt');
    await printReceipt(receiptData); 
  };

  return (
    <>
      {/* begin::User Menu */}
      <div className="ms-1 ms-lg-6 header-user-button">
        {/* begin::Dropdown */}
        {showReceiptButton == true  && (printerConnectionStatus == 'disconnected' ? (
          <div className="search-btn">
            <button
              className="btn btn-icon btn-sm printer-btn fw-bolder "
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
              data-bs-toggle="tooltip"
              title="For receipt, you need to connect to the printer."
              onClick={() => {
                toast.warning('For receipt, you need to connect to the printer.', toastOptions);
              }}
            >
              <KTSVG
                path="/media/icons/receipt_icon.svg"
                className="svg-icon-1 svg-icon-printer"
              />
            </button>
          </div>
        ) :
        (
          <div className="search-btn">
            <button
              className="btn btn-icon btn-sm printer-btn connected fw-bolder "
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
              onClick={() => {
                reprintReceipt();
              }}
            >
              <KTSVG
                path="/media/icons/receipt_icon.svg"
                className="svg-icon-1 svg-icon-printer"
              />
            </button>
          </div>
        ) )
      }
        {(printerConnectionStatus == 'disconnected' ? (
          <div className="search-btn">
            <button
              className="btn btn-icon btn-sm printer-btn fw-bolder "
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
              onClick={() => {
                connectPrinter();
              }}
            >
              <KTSVG
                path="/media/icons/printer_off_icon.svg"
                className="svg-icon-1 svg-icon-printer"
              />
            </button>
          </div>
        ) :
        (
          <div className="search-btn">
            <button
              className="btn btn-icon btn-sm printer-btn connected fw-bolder "
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
              onClick={() => {
                disconnecPrinter();
              }}
            >
              <KTSVG
                path="/media/icons/printer_on_icon.svg"
                className="svg-icon-1 svg-icon-printer"
              />
            </button>
          </div>
        ) )
      }
        {searchButton == true && (
          <div className="search-btn">
            <button
              className="btn btn-icon btn-sm btn-light-danger fw-bolder pulse "
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
              onClick={() => {
                history.push("/search", { prevPage : location.pathname});
                setSearchButton(false);
              }}
            >
              <KTSVG
                path="/media/icons/duotone/General/Search.svg"
                className="svg-icon-1 svg-icon-dark"
              />
            </button>
          </div>
        )}
        <button
          className="btn btn-icon btn-sm btn-light-danger fw-bolder pulse pulse-danger"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
        >
          <KTSVG
            path="/media/icons/duotone/General/User.svg"
            className="svg-icon-1 svg-icon-dark"
          />
          <span className="pulse-ring"></span>
        </button>
        <HeaderUserMenu />
        {/* end::Dropdown */}
      </div>
      {/* end::User Menu */}
    </>
  );
}
