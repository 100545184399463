import React, { useState, useEffect, useRef } from "react";
import { KTSVG } from "../../../../_start/helpers";
import { getExchangeData } from "../../../services/getExchangeData";
import { constants } from "../../../resources/constants";
import { toast, ToastOptions } from "react-toastify";
import { usePageData } from "../../../../_start/layout/core";
import { ConfirmModal } from "../../../../app/modals/ConfirmModal";
interface Props {
    onSwap: (btcAmount: number, amount: number) => void;
    reverse?: boolean;
    operationType: string;
    withdrawPayType: string;
    cardNumber: string;
    btcAddress: any;
}

toast.configure();
const toastOptions: ToastOptions = {
    autoClose: 1000,
    hideProgressBar: true,
    position: "bottom-right",
};

const Swap: React.FC<Props> = ({ onSwap, reverse = false, operationType, withdrawPayType, cardNumber, btcAddress }) => {

    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const { balance } = usePageData();
    const [showModal, setShowModal] = useState(false);
    const [countdown, setCountdown] = useState(30);
    const [inputAmount, setInputAmount] = useState("");
    const [outputAmount, setOutputAmount] = useState<number | null>(null);
    const [inputAmountConfirm, setInputAmountConfirm] = useState("");
    const [outputAmountConfirm, setOutputAmountConfirm] = useState<number | null>(null);
    const [transactionDate, setTransactionDate] = useState('');
    const [exchangeRate, setExchangeRate] = useState<number | null>(null);
    const [networkFee, setNetworkFee] = useState<number | null>(0);
    const [isInputFocused, setIsInputFocused] = useState(false);

    const prevAmountRef = useRef("");

    useEffect(() => {
        const countdownInterval = setInterval(() => {
            setCountdown(oldCountdown => {
                if (oldCountdown <= 0) {
                    return 30;
                } else {
                    return oldCountdown - 1;
                }
            });
        }, 1000);

        return () => {
            clearInterval(countdownInterval);
        };
    }, []);

    useEffect(() => {
        if (countdown === 0) {
            handleAmountChange(); // force update when countdown resets
        }
    }, [countdown]);

    const handleAmountChange = (event?: React.ChangeEvent<HTMLInputElement>) => {
        setLoading(true)
        let inputValue = event ? event.target.value : inputAmount;
        let processedValue = inputValue;

        const onlyNumsAndDot = inputValue?.replace(/[^0-9.]/g, '') || ''; // Only allow numbers and one dot
        const onlyNumsWithOneDot = onlyNumsAndDot.split('.').slice(0, 2).join('.');

        if (onlyNumsWithOneDot.startsWith('0') && !onlyNumsWithOneDot.startsWith('0.') && onlyNumsWithOneDot.length > 1) {
            processedValue = '0.' + onlyNumsWithOneDot.slice(1);
        } else if (onlyNumsWithOneDot === '.') {
            processedValue = '0.';
        } else if (onlyNumsWithOneDot === '' || !inputValue) {
            processedValue = '';
            setLoading(false);
        } else {
            processedValue = onlyNumsWithOneDot;
        }

        const splitVal = processedValue.split('.');
        if ((splitVal.length > 1 && splitVal[1].length > 2) || parseFloat(processedValue) >= constants.inputAmountMax) {
            setLoading(false);
            return;
        }

        setInputAmount(processedValue);
        prevAmountRef.current = processedValue;

        if (timer !== null) {
            clearTimeout(timer);
        }
        if (inputValue === '') {
            setLoading(false);
            setOutputAmount(null);
            return;
        }

        const value = parseFloat(processedValue);
        const delay = 1000; // delay in milliseconds
        const newTimer = setTimeout(async () => {

            const media_id = constants.withdrawMediaId;
            const provider_id = constants.withdrawProviderId;
            const op_type = operationType === "deposit" ? constants.depositOpType : constants.withdrawOpType;

            try {
                if (!isNaN(value) && value > 0) {
                    const response = await getExchangeData(value, media_id, provider_id, op_type, withdrawPayType);
                    const exchangeData = response.data;

                    if (response.code !== 0) {
                        setOutputAmount(null);
                        setExchangeRate(null);
                        toast.error(response.message, toastOptions);
                    } else {
                        if (operationType === 'withdraw') {
                            let formatted_balance = exchangeData.exchange_amount.toLocaleString(undefined, { minimumFractionDigits: 8 })
                            setOutputAmount(formatted_balance);
                        } else {
                            setOutputAmount(exchangeData.exchange_amount);
                        }
                        setExchangeRate(exchangeData.exchange_rate.toFixed(2));
                        setNetworkFee(exchangeData.network_fee);
                        setCountdown(30);
                    }
                } else {
                    setLoading(false);
                    setInputAmount("");
                    setOutputAmount(null);
                }
            } catch (error) {
                console.log("Error getting exchange data:", error);
                setOutputAmount(null);
            } finally {
                setLoading(false);
            }
        }, delay);
        setTimer(newTimer);
    };

    const handleMaxBalanceClick = () => {
        if (balance !== undefined && balance !== null) {
            let maxBalance = balance.toFixed(2).toString();
            if (maxBalance.startsWith("0.")) {
                maxBalance = "0" + maxBalance;
            }
            setInputAmount(maxBalance);

            const event = {
                target: {
                    value: maxBalance,
                },
            } as React.ChangeEvent<HTMLInputElement>;

            handleAmountChange(event);
        } else {
            toast.error("Error getting balance", toastOptions);
        }
    };

    const confirm = async () => {
        const currentDate = new Date().toLocaleDateString();
        const currentTime = new Date().toLocaleTimeString();
        const datetime = `${currentDate} ${currentTime}`;
        setTransactionDate(datetime);
        setShowModal(true);
        setInputAmountConfirm(inputAmount);
        setOutputAmountConfirm(outputAmount);
        setInputAmount("");
        setOutputAmount(null);
    };

    return (
        <div className="swap">
            <div className="input-group mb-5">
                <input
                    type="string"
                    className="form-control"
                    placeholder={operationType === 'cash-withdraw' ? "pts Amount" : (reverse ? "USD Amount" : "pts Amount")}
                    value={inputAmount || ""}
                    onChange={handleAmountChange}
                    onFocus={() => setIsInputFocused(true)}
                    onBlur={() => setIsInputFocused(false)}
                    style={{ paddingRight: "60px" }}
                />
                {operationType !== 'deposit' && !isInputFocused && inputAmount === '' && <div className="input-group-prepend">
                    <button
                        className="btn btn-warning max-balance-btn"
                        onClick={handleMaxBalanceClick}
                    >
                        Max
                    </button>
                </div>}
                <span className="input-group-text">{operationType === 'cash-withdraw' ? "pts" : (reverse ? "USD" : "pts")}</span>
            </div>
            <div className="swap-icon-container">
                {loading ? (
                    <KTSVG
                        path="/media/icons/loading_icon.svg"
                        className="swap-icon svg-icon-1 svg-icon-white"
                    />
                ) : (
                    <KTSVG
                        path="/media/icons/withdraw_icon.svg"
                        className="swap-icon svg-icon-1 svg-icon-white"
                    />
                )}
            </div>
            <div className="input-group mb-5">
                <input
                    type="string"
                    className="form-control"
                    placeholder={operationType === 'cash-withdraw' ? "USD Amount" : reverse ? "pts Amount" : "BTC Amount"}
                    value={outputAmount || ""}
                    readOnly
                    style={{ flexGrow: 1 }}
                />
                <span className="input-group-text">{operationType === 'cash-withdraw' ? "USD" : reverse ? "pts" : "BTC"}</span>
            </div>
            <>
                {inputAmount !== '' && !loading && outputAmount !== null && exchangeRate !== null &&
                    <div className="rate-row">
                        <div className="rate-section">
                            <div className="rate-container">
                                <div className="rate-text-wrapper">
                                    <div className="rate-text">Exchange Rate:</div>
                                    <div className="rate-number">1 {operationType === "withdraw" ? "BTC" : "USD"} ≈ {exchangeRate} pts</div>
                                </div>
                            </div>
                            <div className="rate-container">
                                <div className="rate-text-wrapper">
                                    <div className="rate-text">Transaction Fee: </div>
                                    <div className="rate-number">{(networkFee ? networkFee : 0) + (operationType === "withdraw" ? " BTC" : " USD")}</div>
                                </div>
                            </div>
                        </div>
                        <div className="countdown-section">
                            <div id="countdown">
                                <div id="countdown-number">{countdown}</div>
                                <svg>
                                    <circle r="18" cx="20" cy="20"></circle>
                                </svg>
                            </div>
                        </div>
                    </div>
                }
            </>
            <button
                className="btn btn-primary transactions-btn"
                onClick={confirm}
                disabled={outputAmount === null || loading}
                style={{ backgroundColor: "#007bff" }}
            >
                {(operationType === 'cash-withdraw' || operationType === 'withdraw') ? 'Withdraw' : 'Deposit'}
            </button>
            <ConfirmModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                operationData={{
                    operation_type: operationType,
                    card_number: cardNumber,
                    btc_address: btcAddress,
                    prev_balance: balance,
                    input_amt: inputAmountConfirm,
                    output_amt: outputAmountConfirm,
                    transaction_fee: networkFee ? networkFee : 0,
                    exchange_rate: exchangeRate,
                    date: transactionDate
                }}
                sendTransaction={onSwap}

            />
        </div>
    );
};

export { Swap };
