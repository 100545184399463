import React, { useState } from "react";
import walletconnectLogo from "../../../_start/assets/images/walletconnect_btn.png";
import metamaskLogo from "../../../_start/assets/images/metamask_btn.png";
import { handleWalletConnectConnection } from "./wallet/WalletConnectConnection";
import { handleMetaMaskConnection } from "./wallet/MetamaskConnection";

const isMetamaskInstalled = window.ethereum ? true : false;

export function Login() {
  const [loading, setLoading] = useState(false);

  return (
    <div className="login-page">
      {/* begin::Title */}
      <div className="pb-lg-15">
        <div className="text-center login-text-container">

          <h3 className="fw-bolder text-dark display-6">Welcome to <span style={{ color: 'red' }}>iceTor</span> </h3>
          <div className="text-muted fw-bold">
            Start by connecting with one of the wallets below.
            Be sure to store your private keys or seed phrase securely.
            Never share them with anyone.{" "}
          </div>
          <div><div className="text-muted fw-bold fs-3"><span className="text-primary fw-bolder">Connect with Your Wallet</span></div></div>
        </div>
      </div>
      <div className="pb-lg-0 pb-5 mt-5 wallet-connetion-container">
        <button
          type="submit"
          id="kt_login_signin_form_submit_button"
          className="btn fw-bolder fs-6 px-8 py-4 my-3 me-3 connect-btn"
          onClick={handleWalletConnectConnection}
        >
          {!loading && <div><img className="connect-btn-icon" src={walletconnectLogo} /><br></br><span>WalletConnect</span></div>}
          {loading && (
            <span className="spinner" style={{ display: "block" }}>
              Please wait...{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        {isMetamaskInstalled && (
          <button
            type="submit"
            id="kt_login_signin_form_submit_button"
            className="btn fw-bolder fs-6 px-8 py-4 my-3 me-3 connect-btn"
            onClick={handleMetaMaskConnection}
          >
            {!loading && <div><img className="connect-btn-icon" src={metamaskLogo} /><br></br><span>Metamask</span></div>}
            {loading && (
              <span className="spinner" style={{ display: "block" }}>
                Please wait...{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        )}
      </div>
      {/* end::Action */}
    </div>
  );
}