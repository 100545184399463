import axios from "axios";
import { constants } from '../resources/constants';

const registerUserMedia = async (user_address, wallet_address, media_no, media_id, provider_id) => {
    
    const options = {
        method: 'POST',
        timeout: 5000, // Wait for 5 seconds
        url: constants.apiURL + `/registerUser`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            user_address: user_address,
            wallet_address: wallet_address,
            media_no: media_no,
            media_id: media_id,
            provider_id: provider_id
          }
    };

    console.log('RegisterUserMedia Request -> ', options);

    try{
        const response = await axios.request(options);  
        console.log('RegisterUserMedia Response -> ', response);
        
        if(response.status !== 200){
            return null;
        }

        const resp_data = response.data;
        
        return resp_data;
    }catch(error) {
        console.error(error);
        return null
    };
}

export default registerUserMedia;