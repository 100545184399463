import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Switch } from "react-router-dom";
import { useDisconnect } from "wagmi";

export function Logout() {
  const dispatch = useDispatch();
  const { disconnect } = useDisconnect();

  useEffect(() => {
    dispatch({ type: "auth/login" });
    disconnect();
    localStorage.removeItem("authenticatedCashierAddress");
    document.location.reload();
  }, [dispatch, disconnect]);

  return (
    <Switch>
      <Redirect to="/auth/login" />
    </Switch>
  );
}
