import React, { useState, useEffect, ChangeEvent } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Location } from "history";
import { KTSVG, toAbsoluteUrl } from "../../../_start/helpers";
import QRScanner from "../../helpers/QRScanner/QRScanner";
import { Buffer } from "buffer";
import { usePageData } from "../../../_start/layout/core";
import getMediaInfo from "../../services/getMediaInfo";
import { constants } from "../../resources/constants";
import { toast, ToastOptions, ToastPosition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import searchImage from "../../../_start/assets/images/search_card_img.png";
import { connectToPrinter, isPrinterConnected } from '../../helpers/ReceiptPrinter/printerUtils';
import { isValidCardNumber } from '../../helpers/LuhnChecker';

toast.configure();
const toastOptions: ToastOptions = {
  autoClose: 3000,
  hideProgressBar: true,
  position: "bottom-right" as ToastPosition,
};
interface LocationState {
  prevPage: string;
}

const CardSearch: React.FC = () => {
  const location = useLocation() as Location<LocationState>;
  const [qrData, setQrData] = useState("");
  const [showQrReader, setShowQrReader] = useState(false);
  const { setCardNumber, setPrinterConnectionStatus } = usePageData();
  const [cardNumberInput, setCardNumberInput] = useState<string>();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const QRPattern = constants.regexQR;

  useEffect(() => {
    const initializePrinter = async () => {
      if (location?.state?.prevPage === "/profile") return;
      const printerConnected = isPrinterConnected();
      setPrinterConnectionStatus(printerConnected ? 'connected' : 'disconnected');

      if (!printerConnected) {
        const connectPrinterRes = await connectToPrinter();
        if (connectPrinterRes) {
          setPrinterConnectionStatus('connected');
        } else {
          toast.warning("You should connect to the printer with the printer button for receipt.", toastOptions);
        }
      }
    }
    initializePrinter();
  }, []);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const formattedValue = event.target.value.replace(/\D/g, '');
    const formattedText = formattedValue.split('').map((char, i) => {
      return (i === 5 || i === 10) ? '-' + char : char;
    }).join('');
    setCardNumberInput(formattedText);
  };

  const showToastIfNotActive = (toastId: string, message: string) => {
    if (!toast.isActive(toastId)) {
      toast.error(message, { ...toastOptions, toastId });
    }
  }

  const findMedia = async () => {
    const TOAST_ID_INVALID_MEDIA = "toast_id_invalid_media";
    const TOAST_ID_MEDIA_NOT_FOUND = "toast_id_media_not_found";
    const TOAST_ID_NO_CARD = "toast_id_no_card";

    setLoading(true);

    if (!!cardNumberInput) {
      let checksum = await isValidCardNumber(cardNumberInput.replace(/-/g, "").substring(0, 10));

      if (cardNumberInput.charAt(12) !== checksum.toString()) {
        showToastIfNotActive(TOAST_ID_INVALID_MEDIA, "Invalid media ID!");
      }
      else if (QRPattern.test(cardNumberInput)) {
        try {
          const mediaResp = await getMediaInfo(cardNumberInput);
          setCardNumber(cardNumberInput);

          if (!mediaResp) {
            showToastIfNotActive(TOAST_ID_MEDIA_NOT_FOUND, 'Media ID not found!');
          } else {
            history.push("/profile", {
              btcAddress: mediaResp.data.wallet_address,
              cardNumber: cardNumberInput,
            });
          }
        }
        catch (error) {
          toast.error(error, toastOptions);
        }
      }
    }
    else {
      showToastIfNotActive(TOAST_ID_NO_CARD, "Please enter card number.");
    }
    setLoading(false);
  };

  function handleQrReader() {
    setShowQrReader(!showQrReader);
  }
  function handleCardNumber(data: string) {
    setShowQrReader(false);
    QRPattern.test(data) ? setCardNumberInput(data) : parseQRData(data);
  }

  const calculate_checksum = (input: any) => {
    let nCheck = 0,
      bEven = true;
    input = input.replace(/\D/g, "");

    for (let n = input.length - 1; n >= 0; n--) {
      let cDigit = input.charAt(n),
        nDigit = parseInt(cDigit, 10);
      if (bEven && (nDigit *= 2) > 9) {
        nDigit -= 9;
      }
      nCheck += nDigit;
      bEven = !bEven;
    }

    const lastDigit = (1000 - nCheck) % 10;
    return lastDigit;
  };

  const parseQRData = (data: any) => {
    console.log("parseQRData data --> ", data);
    //base64 to hex
    const hex_data = Buffer.from(data, "base64").toString("hex");
    //check if hex_data starts with 24
    if (hex_data.substring(0, 2) !== "24") {
      return;
    }
    //remove first 20 bytes
    const number_block = hex_data.substring(40);
    const number_field = number_block.slice(0, 16);
    const media_no = number_field.slice(5, -1);

    const checksum = calculate_checksum(media_no);
    let formatted_media_no =
      media_no.slice(0, 5) + "-" + media_no.slice(5, 10) + "-" + checksum;
    setCardNumberInput(formatted_media_no);
  };
  return (
    <div className="search-container d-flex flex-column align-items-center">
      <div className="w-100">
        <div className="search-body" style={{ backgroundColor: "white" }}>
          <div
            className="stepper stepper-1 d-flex flex-column flex-row-fluid"
            id="kt_modal_create_app_stepper"
          >
            <div
              className="d-flex flex-column w-100 pt-15 pt-lg-0 search-image-wrapper"
              style={{
                backgroundImage: `url('${searchImage}')`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "50vh", // This will be responsive
              }}
            />
            <div className="d-flex flex-column flex-lg-row-fluid py-10">
              <div
                className="stepper stepper-1 d-flex flex-column flex-row-fluid"
                id="kt_modal_create_app_stepper"
              >
                <div className="d-flex flex-row-fluid justify-content-center">
                  <form
                    className="pb-5 w-100 w-md-400px w-xl-500px"
                    noValidate
                    id="kt_modal_create_app_form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      findMedia();
                    }}
                  >
                    <div
                      className="pb-5 current"
                      data-kt-stepper-element="content"
                    >
                      <div className="w-100">
                        <div className="fv-row mb-12">
                          <label className="fs-6 fw-bolder text-dark form-label">
                            Enter Card Number or Scan
                          </label>
                          <div className="row">
                            <div className="col-md-10 col-10 input-wrapper">
                              <input
                                type="text"
                                className="form-control form-control-lg form-control-solid addressInput"
                                name="appname"
                                placeholder="Type card number or scan your QR with camera"
                                value={cardNumberInput}
                                onChange={handleInputChange}
                                maxLength={13}
                              />
                              <span className="popover">
                                * Type card number or scan your QR with camera
                              </span>
                            </div>
                            <div className="col-md-2 col-2">
                              <span
                                className="symbol symbol-50px me-6"
                                onClick={handleQrReader}
                              >
                                <span className="symbol-label camera-btn">
                                  {!showQrReader ? (
                                    <KTSVG
                                      path="/media/icons/camera_icon.svg"
                                      className="svg-icon-1 svg-icon-1-green"
                                    />
                                  ) : (
                                    <KTSVG
                                      path="/media/icons/close_icon.svg"
                                      className="svg-icon-1 svg-icon-1-green"
                                    />
                                  )}
                                </span>
                              </span>
                            </div>
                          </div>
                          {showQrReader && (
                            <QRScanner
                              data={qrData}
                              onDataChange={handleCardNumber}
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between step-button">
                      {!loading ? (
                        <button
                          type="submit"
                          className="btn search-button btn-lg next-btn fw-bolder py-4 ps-8 me-3"
                          onClick={findMedia}
                        >
                          Enter{" "}
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-lg next-btn fw-bolder py-4 ps-8 me-3"
                          disabled
                        >
                          <img
                            src={toAbsoluteUrl("/media/load.gif")}
                            className="loader"
                            alt="loading..."
                          />
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CardSearch };
