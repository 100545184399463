import React, { useState } from "react";
import { KTSVG } from "../../../../_start/helpers";
import { useWindowWidth } from "../../../helpers/useWindowWidth";

interface Props {
    onActionClick: (action: string) => void;
}

const Actions: React.FC<Props> = ({ onActionClick }) => {

    const windowWidth = useWindowWidth();
    const [selectedAction, setSelectedAction] = useState("");

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        onActionClick(event.target.value);
        setSelectedAction(event.target.value);
    };

    const handleClick = (action: string) => {
        setSelectedAction(action);
        onActionClick(action);
    };

    if (windowWidth > 520) {
        return (
            <div className="d-flex flex-row">
                <span className="action-buttons">
                    <button
                        onClick={() => handleClick("transactions")}
                        className={`btn symbol-label transaction-btn align-items-end ${selectedAction === "transactions" ? "selected" : ""}`}>
                        <KTSVG
                            path="/media/icons/transaction_icon.svg"
                            className="svg-icon-1 svg-icon-white action-icon"
                        />
                        <span className="action-text">Transactions</span>
                    </button>
                </span>
                <span className="action-buttons">
                    <button
                        onClick={() => handleClick("withdraw")}
                        className={`btn symbol-label withdraw-btn align-items-end ${selectedAction === "withdraw" ? "selected" : ""}`}>
                        <KTSVG
                            path="/media/icons/withdraw_icon.svg"
                            className="svg-icon-1 svg-icon-white action-icon"
                        />
                        <span className="action-text">Withdraw</span>
                    </button>
                </span>
                <span className="action-buttons">
                    <button
                        onClick={() => handleClick("deposit")}
                        className={`btn symbol-label deposit-btn align-items-end ${selectedAction === "deposit" ? "selected" : ""}`}>
                        <KTSVG
                            path="/media/icons/deposit_icon.svg"
                            className="svg-icon-1 svg-icon-white action-icon"
                        />
                        <span className="action-text">Deposit</span>
                    </button>
                </span>
            </div>
        );
    } else {
        return (
            <select onChange={handleSelectChange} className="form-select" value={selectedAction}>
                <option value="transactions">Transactions</option>
                <option value="withdraw">Withdraw</option>
                <option value="deposit">Deposit</option>
            </select>
        );
    }
};

export { Actions };
