import React, { useEffect, useState } from "react";
import { useAccount } from 'wagmi'
import { toAbsoluteUrl } from "../../../_start/helpers";
import { toast, ToastOptions } from "react-toastify";

toast.configure();
const toastOptions: ToastOptions = {
    autoClose: 2000,
    hideProgressBar: true,
    position: "bottom-right"
};

const CashierAddressControl = () => {

    const { address } = useAccount();
    const [cashiers, setCashiers] = useState<string[]>([]);
    const [isCashier, setIsCashier] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        fetch(toAbsoluteUrl("/config/index.json"))
            .then(response => response.json())
            .then(data => {
                setCashiers(data.cashiers.map((cashier: string) => cashier.toLowerCase()));
                setIsLoading(false);
            })
            .catch(error => console.error('Error:', error));
    }, []);

    useEffect(() => {
        if (!isLoading && address) {
            if (cashiers.includes(address.toLowerCase())) {
                setIsCashier(true);
                localStorage.setItem("authenticatedCashierAddress", address);
            } else {
                setIsCashier(false);
                localStorage.removeItem("authenticatedCashierAddress");
                toast.error('Only cashiers are allowed to connect.', toastOptions);
            }
        }
    }, [address, cashiers, isLoading]);

    return { isCashier, isLoading, cashiers };
}

export { CashierAddressControl };