import React, { useState } from "react";
import moment from "moment";
import { Swap } from "./Swap";
import { withdrawMedia, debitProviderMedia } from "../../../services";
import { toast, ToastOptions } from "react-toastify";
import { constants } from "../../../resources/constants";
import { TransactionModal } from "./../../../modals/TransactionModal";
import { usePageData } from "../../../../_start/layout/core";
import { printReceipt, isPrinterConnected } from '../../../helpers/ReceiptPrinter/printerUtils';

type Props = {
  btcAddr: string;
  cardNum: string;
  registrationStatus: boolean;
  getMediaBalanceData: () => void;
};

toast.configure();
const toastOptions: ToastOptions = {
  autoClose: 3000,
  hideProgressBar: true,
  position: "bottom-right",
};

const Withdraw: React.FC<Props> = ({ btcAddr, cardNum, registrationStatus, getMediaBalanceData }) => {

  const { balance, setBalance } = usePageData();
  const [cardNumber, setCardNumber] = useState<string>(cardNum);
  const { btcAddress, setBTCAddress } = usePageData();
  const [selectedOperation, setSelectedOperation] = useState<string>("cash-withdraw");
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transactionFee, setTransactionFee] = useState(0);
  const currentDate = moment().format("MM/DD/YYYY");
  const currentTime = moment().format("hh:mm A");
  const datetime = `${currentDate} ${currentTime}`;
  const { setShowReceiptButton } = usePageData();


  const handleSwap = async (btcAmount: number, amount: number) => {
    setLoading(true);
    if (!!balance && balance < amount) {
      toast.error("Not enough balance to withdraw", toastOptions);
      setLoading(false);
      return;
    } else {
      if (selectedOperation !== "cash-withdraw") {
        try {
          const responseData = await withdrawMedia(
            cardNumber,
            btcAddress,
            amount,
          );
          setLoading(false);
          setShowModal(true);
          setTransactionFee(responseData.network_fee);
          if (balance !== undefined && balance !== null) {
            await getMediaBalanceData();
          }
          try {
            const printerConnected = isPrinterConnected();
            /*if(!printerConnected) {
              await connectToPrinter();
            }*/
            const receiptText = `
      ICETOR
      123 Main St, Philippines
      555-555-1234

      Receipt Number: 12345
      Operation Type: 'Crypto Withdraw'
      Card Number: ${cardNumber}
      BTC Address: ${btcAddress}
      Date: ${datetime}
      Exchange Rate: ${responseData.exchange_rate}

                      Qty        BTC
      -------------------------------------
      Amount            ${amount}          ${btcAmount}
      Transaction Fee   1           ${responseData.network_fee}
      -------------------------------------
      Total Withdraw:              ${(btcAmount)}


      
  `;
            localStorage.setItem('lastReceipt', receiptText);
            setShowReceiptButton(true);

            if (printerConnected) {
              await printReceipt(receiptText);
            }
          } catch (error) {
            toast.error('Printer connection error!', toastOptions);
          }
        } catch (error) {
          toast.error("Please try again later", toastOptions);
          setLoading(false);
        }
      }
      else {
        try {
          const responseData = await debitProviderMedia(
            cardNumber,
            btcAddress,
            amount
          );
          setTransactionFee(responseData.data.operation_fee)
          setLoading(false);
          if (responseData && responseData.code === 0 && balance !== undefined && balance !== null) {
            setBalance(balance - amount);
          }
          setShowModal(true);
          try {
            const printerConnected = isPrinterConnected();
            /*if(!printerConnected) {
              await connectToPrinter();
            }*/

            const receiptText = `
      ICETOR
      123 Main St, Philippines
      555-555-1234

      Receipt Number: 12345
      Operation Type: 'Cash Withdraw'
      Card Number: ${cardNumber}
      BTC Address: ${btcAddress}
      Date: ${datetime}

                      Qty        USD
      -------------------------------------
      Amount            ${amount}          ${btcAmount}
      Transaction Fee   1           ${responseData.data.operation_fee}
      -------------------------------------
      Total Withdraw:              ${(btcAmount)}


      
    `;
            localStorage.setItem('lastReceipt', receiptText);
            setShowReceiptButton(true);

            if (printerConnected) {
              await printReceipt(receiptText);
            }
          } catch (error) {
            toast.error('Printer connection error!', toastOptions);
          }
        } catch (error) {
          setLoading(false);
          toast.error("Error during withdraw:", toastOptions);
        }
      }
    }
  };

  return (
    <div className="container px-10 py-10">
      <div className="withdraw-tabs">
        <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6" >
          <li className="nav-item">
            <a
              className={
                selectedOperation === "cash-withdraw" ? "nav-link active" : "nav-link"
              }
              data-bs-toggle="tab"
              onClick={() => setSelectedOperation("cash-withdraw")}
            >
              Withdraw Cash
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                selectedOperation === "withdraw" ? "nav-link active" : "nav-link"
              }
              data-bs-toggle="tab"
              onClick={() => setSelectedOperation("withdraw")}
            >
              Withdraw Crypto
            </a>
          </li>
        </ul>
      </div>
      {selectedOperation === "cash-withdraw" ? (
        <div className="col-12 swap-textbox-container" key="cash">
          <Swap
            onSwap={handleSwap}
            operationType="cash-withdraw"
            withdrawPayType={constants.withdrawPayTypeCash}
            cardNumber={cardNumber} 
            btcAddress={null} 
          />
        </div>
      ) : (!!btcAddress ?
        <div className="col-12 swap-textbox-container" key="withdraw">
          <Swap
            onSwap={handleSwap}
            operationType="withdraw"
            withdrawPayType={constants.withdrawPayTypeBTC}
            cardNumber={cardNumber} 
            btcAddress={btcAddress} 
          />
        </div> :
        <div className="wtd-info-section">
          <span className="wtd-info">For withdraw to the crypto wallet, the card must first be matched with the BTC address.</span>
        </div>
      )}
      {/* begin::Modals */}
      <TransactionModal show={showModal} operation='WITHDRAW' handleClose={() => setShowModal(false)} />
      {/* end::Modals */}
    </div>

  );
};

export { Withdraw };
