import { connect } from '@wagmi/core';
import { mainnet, optimism, polygon } from 'wagmi/chains';
import { WalletConnectConnector } from '@wagmi/core/connectors/walletConnect';
import { toast, ToastOptions, ToastPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { constants } from "../../../constants/constant";

toast.configure();
const toastOptions: ToastOptions = {
    autoClose: 1000,
    hideProgressBar: true,
    position: "bottom-right" as ToastPosition,
};

export const handleWalletConnectConnection = async () => {
    try {
        await connect({
            connector: new WalletConnectConnector({
                chains: [polygon],
                options: {
                    projectId : constants.WalletConnectProjectId,
                    showQrModal: true,
                },
            }),
        });
    }
    catch (error) {
        toast.error('WalletConnect connection failed. Please try again.', toastOptions);
    }
};
