import React, { useState, useEffect } from 'react';
import { Location } from 'history';
import { useLocation } from 'react-router-dom';
import { Swap } from "./Swap";
import { topupProviderMedia } from '../../../services';
import { constants } from "../../../resources/constants";
import { toast, ToastOptions, ToastPosition } from "react-toastify";
import { TransactionModal } from "../../../../app/modals/TransactionModal";
import { usePageData } from "../../../../_start/layout/core";
import { printReceipt, isPrinterConnected } from '../../../helpers/ReceiptPrinter/printerUtils';
interface LocationState {
  btcAddress: string;
  cardNumber: string;
}
type Props = {
  btcAddress: string;
  cardNumber: string;
  registrationStatus: boolean;
  getMediaBalanceData: () => void;
}

toast.configure();
const toastOptions: ToastOptions = {
  autoClose: 2000,
  hideProgressBar: true,
  position: "bottom-right" as ToastPosition,
};

const Deposit: React.FC<Props> = ({ getMediaBalanceData }) => {
  const { balance } = usePageData();
  const [btcAddress, setBtcAddress] = useState<string>('');
  const [cardNumber, setCardNumber] = useState<string>('');
  const location = useLocation() as Location<LocationState>;
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const { setShowReceiptButton } = usePageData();

  useEffect(() => {
    if (location.state) {
      setBtcAddress(location.state.btcAddress);
      setCardNumber(location.state.cardNumber);
    }
  }, [location]);

  const handleSwap = async (ptsAmount: number, amount: number) => {
    setLoading(true);
    const provider_id = constants.withdrawProviderId;

    try {
      const responseData = await topupProviderMedia(cardNumber, btcAddress, amount, provider_id);
      setShowModal(true);
      if (responseData && responseData.code === 0 && balance !== undefined && balance !== null) {
        await getMediaBalanceData();
      }
      try {
        const printerConnected = isPrinterConnected();
        /*if(!printerConnected) {
          await connectToPrinter();
        }*/
        const currentDate = new Date().toLocaleDateString();
        const currentTime = new Date().toLocaleTimeString();
        const datetime = `${currentDate} ${currentTime}`;

        let prevBalance = 0;
        if (!!balance) {
          prevBalance = balance;
        } else {
          prevBalance = 0;
        }

        const receiptText = `
      ICETOR
      123 Main St, Philippines
      555-555-1234
  
      Receipt Number: 12345
      Operation Type: Deposit
      Card Number: ${cardNumber}
      Date: ${datetime}
      Exchange Rate: ${responseData.data.exchange_rate}
      Transaction Fee: ${responseData.data.operation_fee}
      
                      Qty      pts
      -------------------------------------
      Balance                     ${prevBalance}
      Amount           ${amount}          ${ptsAmount}
      -------------------------------------
      Total Balance:              ${(ptsAmount + prevBalance).toFixed(8)}
  
      
  
  `;
      localStorage.setItem('lastReceipt', receiptText);
      setShowReceiptButton(true);

      if (printerConnected) {
        await printReceipt(receiptText);
      }
      } catch (error) {
        console.log("erro",error)
        toast.error('Printer connection error!', toastOptions);
      }
      setLoading(false);
      //toast.success("Deposit Successful", toastOptions);
    } catch (error) {
      setLoading(false);
      toast.error("Error during deposit:", toastOptions);
    }
  };

  return (
    <div className="container px-10 py-10">
      <span className="profile-header-transaction">Deposit</span>
      <div className="col-12 swap-textbox-container">
        <Swap onSwap={handleSwap} reverse operationType="deposit" withdrawPayType='USD' cardNumber={cardNumber} btcAddress={null} />
      </div>
      {/* begin::Modals */}
      <TransactionModal show={showModal} operation= 'DEPOSIT' handleClose={() => setShowModal(false)} />
      {/* end::Modals */}
    </div>
  );
};

export { Deposit };