import React from "react";
import QrScanner from 'qr-scanner';
import { useEffect, useRef, useState } from 'react';
import './QRScanner.scss';

interface QRScannerProps {
    data: string;
    onDataChange: (newData: string) => void;
}

export function QRScanner({ data, onDataChange }: QRScannerProps) {
    const videoElementRef = useRef<HTMLVideoElement>(null);
    const [scanned, setScannedText] = useState('');

  useEffect(() => {
    const video = videoElementRef.current!;
    const qrScanner = new QrScanner(
      video,
      (result: any) => {
        console.log('decoded qr code:', result);
        setScannedText(result.data);
        onDataChange(result.data);
      },
      {
        returnDetailedScanResult: true,
        highlightScanRegion: true,
        highlightCodeOutline: true,
      }
    );
    qrScanner.start();
    console.log('start');

    return () => {
      console.log(qrScanner);
      qrScanner.stop();
      qrScanner.destroy();
    };
  }, []);

  // const qrScanner = new QrScanner(videoElement, (result) =>
  //   console.log('decoded qr code:', result)
  // );

  return (
    <div>
      <div className="videoWrapper">
        <video className="qrVideo" ref={videoElementRef} />
      </div>
    </div>
  );
};
export default QRScanner;
