/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../_start/helpers";
import QRScanner from "./../../helpers/QRScanner/QRScanner";
import { usePageData } from "../../../_start/layout/core";
import { getMediaList, registerUserMedia } from "../../services/index";
import { useAccount } from "wagmi";
import { constants } from "../../resources/constants";
import { toast, ToastOptions, ToastPosition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReturnObj } from "../../model/ReturnObj";
import { bech32 } from 'bech32';

toast.configure();
const toastOptions: ToastOptions = {
  autoClose: 5000,
  hideProgressBar: true,
  position: "bottom-right" as ToastPosition,
};
interface Props {
  card_number: string;
}

const RegisterPage: React.FC<Props> = ({card_number}) => {
  const [qrData, setQrData] = useState("");
  const [showQrReader, setShowQrReader] = useState(false);
  const [cardNum, setCardNum] = useState<string>(card_number);
  const [btcNumInput, setBtcNumInput] = useState<string>();
  const { btcAddress, setBTCAddress } = usePageData();
  const [loading, setLoading] = useState(false);
  const [registrationStatus, setRegistrationStatus] = useState(false);
  const BTCPattern = constants.btcAddressRegex;

  const { address } = useAccount();

  const submit = async () => {
    setLoading(true);
    if(!!btcNumInput) { 
      let isValid =  isValidBech32(btcNumInput);
      if(isValid == true) {
        try {
          const mediaResp = await getMediaList();
          const registrationResp = await registerUserMedia(
            address,
            btcNumInput,
            cardNum,
            mediaResp[0].id,
            mediaResp[0].provider_id
          );
          console.log("registrationResp",registrationResp)
          if (registrationResp.code === ReturnObj.OK) {
            setBTCAddress(btcNumInput);
            setRegistrationStatus(true)
            toast.success("Registration successful!", toastOptions);
            setLoading(false);
          } else {
            toast.error(registrationResp.message, toastOptions);
            setLoading(false);
          }
        } catch (error) {
          toast.error(error, toastOptions);
          setLoading(false);
        }
      } else {
        toast.error("Invalid BTC Address!", toastOptions);
        setLoading(false);
      }
    } else {
      toast.error("Please enter BTC address or scan QR with camera.", toastOptions);
      setLoading(false);
    }
  };

  function handleQrReader() {
      setBtcNumInput('');
      setShowQrReader(!showQrReader);
  }
  function handleWalletAddress(data: string) {
    console.log("btc adr: ", data);
    setBtcNumInput(data);
    setShowQrReader(!showQrReader);
  }

  function isValidBech32(address: any) { 
    try {
     bech32.decode(address);
     return true; 
    } catch (e) {return false; } 
  }


  return (
    <>
      {/* begin::Card */}
     { !registrationStatus && <div className={`crypto-regist-card mb-5`}>
        {/* <!--begin::Header--> */}
        <div className={`card-header border-0 pt-5`}>
          <h3 className="card-title align-items-start flex-column crypto-regist">
            <span className="card-label fw-bolder crypto-regist-heading">
              Match a Crypto Wallet Address
            </span>
          </h3>
        </div>
        {/* <!--end::Header--> */}

        {/* <!--begin::Body--> */}
        <div className="fv-row mb-8">
          <div className="row wallet-input">
            {!showQrReader && (
              <div className="col-md-10 col-10 input-wrapper">
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid addressInput"
                  name="appname"
                  placeholder="Type BTC Address or scan QR with wallet"
                  value={btcNumInput}
                  onChange={(event) => {
                    setBtcNumInput(event.target.value);
                  }}
                />
                <span className="popover">
                  * Type BTC Address above or scan QR with wallet.
                </span>
              </div>
            )}
            <div className="col-md-2 col-2 cam-btn">
              <span
                className="symbol symbol-50px me-6"
                onClick={handleQrReader}
              >
                <span className="symbol-label register-camera-btn">
                {!showQrReader ? (
                  <KTSVG
                    path="/media/icons/camera_icon.svg"
                    className="svg-icon-1 svg-icon-warning"
                    />
                ) : (
                  <KTSVG
                    path="/media/icons/close_icon.svg"
                    className="svg-icon-1 svg-icon-gray"
                  />
                )}
                </span>
              </span>
            </div>
          </div>
          {showQrReader && (
            <QRScanner data={qrData} onDataChange={handleWalletAddress} />
          )}
        </div>
        <div className="d-flex justify-content-end step-button">
          {!loading ? (
            <div className="mr-2 mb-5">
              <button
                type="button"
                className="btn btn-lg register-btn fw-bolder py-4 ps-8 me-3 "
                onClick={submit}
              >
                Register{" "}
              </button>
            </div>
          ) : (
            <div className="mr-2 mb-5">
              <button
                type="button"
                className="btn btn-lg next-btn fw-bolder py-4 ps-8 me-3"
              >
                <img
                  src={toAbsoluteUrl("/media/load.gif")}
                  className="loader"
                  alt="loading..."
                />
              </button>{" "}
            </div>
          )}
        </div>
      </div> }
      {/* end::Card */}
    </>
  );
};

export { RegisterPage };
