import axios from "axios";
import { constants } from '../resources/constants';

const getMediaInfo = async (media_no) => {
    
    const options = {
        method: 'POST',
        timeout: 5000, // Wait for 5 seconds
        url: constants.apiURL + `/getMediaInfo`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            media_no: media_no,
          }
    };

    console.log('getMediaInfo Request -> ', options);

    try{
        const response = await axios.request(options);  
        console.log('getMediaInfo Response -> ', response);
        
        if(response.status !== 200){
            return null;
        }

        const resp_data = response.data;

        if(resp_data.code !== 0){
            return null;
        }
        
        return resp_data;
    }catch(error) {
        console.error(error);
        return null
    };
}

export default getMediaInfo;