import React from "react";
import { Link } from "react-router-dom";
import { KTSVG } from "../../../helpers";
import { useAccount } from "wagmi";
import { disconnect } from "@wagmi/core";

export function HeaderUserMenu() {
  const { address } = useAccount();

  const clearLocalStorage = () => {
    localStorage.clear();
  };

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold menu-wdt"
      data-kt-menu="true"
    >
      <div
        className="menu-content fw-bold d-flex align-items-center bgi-no-repeat bgi-position-y-top rounded-top header-profile-menu"
      >
        <div className="symbol symbol-45px mx-5 py-5">
          <span className="text-white fw-bolder fs-4">Hello,</span>
          <span className="text-white fw-bold fs-7 d-block">
            {address ? address?.substr(0, 10) + "..." + address?.substr(-10) : ''}
          </span>
        </div>
      </div>

      {/* begin::Row */}
      <div className="row">
        <Link
          to="/logout"
          className="col text-center py-10 btn btn-active-color-primary rounded-0"
          data-kt-menu-dismiss="true"
          onClick={() => { disconnect(); clearLocalStorage(); }}
        >
          <div className="signout-container">
            <KTSVG
              className="svg-icon-3x me-n1 logout-btn"
              path="/media/icons/duotone/Navigation/Sign-out.svg"
            />
            <span className="fw-bolder fs-6 d-block pt-3">Sign Out</span>
          </div>
        </Link>
      </div>
      {/* end::Row */}
    </div>
  );
}
