import React, { useState, useRef } from "react";
import { KTSVG } from "../../../../_start/helpers";

const Transactions: React.FC = () => {
  const currentDate = new Date().toLocaleDateString();

  return (
    <div className="container px-10 py-10">
      <span className="profile-header-transaction">Transactions</span>
      {Array(1)
        .fill(null)
        .map((_, index) => (
          <div className="row grey-row" key={index}>
            <div className="col d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div className="symbol symbol-55px">
                  <span className="symbol-label deposit-btn align-items-end">
                    <KTSVG
                      path="/media/icons/point_icon.svg"
                      className="svg-icon-1 svg-icon-light action-icon-no-opacity"
                    />
                  </span>
                </div>
                <div>
                  <div className="transaction-label">Transaction {index + 1}</div>
                  <div className="transaction-date">{currentDate}</div>
                </div>
              </div>
              <div className="transaction-amount">+0</div>
            </div>
          </div>
        ))}
    </div>
  );
};

export { Transactions };
