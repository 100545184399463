import axios from "axios";
import { constants } from '../resources/constants';

export async function getExchangeData(amount, media_id, provider_id, op_type, withdrawPayType) {
    const url = constants.apiURL + "/getExchangeData";

    const data = {
        amount: amount * 100,
        media_id,
        provider_id,
        op_type,
    };

    if (op_type === constants.withdrawOpType) {
        data['quote_currency'] = withdrawPayType;
    } else if (op_type === constants.depositOpType) {
        if(withdrawPayType === constants.withdrawPayTypeBTC) {
            data['base_currency'] = "BTC";
        } else {
            data['base_currency'] = "USD";
        }
    }

    try {
        const response = await axios.post(url, data, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching exchange data:", error);
        throw error;
    }
}