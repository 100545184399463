import React, { useState, useEffect } from "react";
import { toAbsoluteUrl } from "../../../../_start/helpers";
import { getMediaInfo } from "../../../services/index";
import { usePageData } from "../../../../_start/layout/core";
import { KTSVG } from "../../../../_start/helpers";

type Props = {
  btc_address: string;
  card_number: string;
  getMediaBalanceData: () => void;
};

const CardSection: React.FC<Props> = ({
  btc_address,
  card_number,
  getMediaBalanceData,
}) => {
  const { balance } = usePageData();
  const [cardNum, setCardNum] = useState<string>(card_number);
  const [btcAddr, setBtcAddr] = useState<string>(btc_address);
  const { btcAddress, setBTCAddress } = usePageData();
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    setBTCAddress(btc_address);
  }, [!!btc_address]);

  useEffect(() => {
    const mediaData = async () => {
      const mediaResp = await getMediaInfo(cardNum);
      if (!!mediaResp) {
        setBTCAddress(mediaResp.data.wallet_address);
      }
    };
    mediaData();
  }, [cardNum]);

  const handleReloadClick = () => {
    getMediaBalanceData();
    const newRotation = rotation - 180;
    setRotation(newRotation);
    const rotationStyle = `translateZ(0px) rotateZ(${newRotation}deg)`;

    const svgElement = document.querySelector(".svg-icon-black svg") as HTMLElement;
    if (svgElement) {
      svgElement.style.transform = rotationStyle;
    }
  };

  return (
    <>
      <div className="card-section">
        {/* begin::Card */}
        <div
          className="card card-custom card-preview-section"
          style={{
            backgroundImage: `url('${toAbsoluteUrl(
              "/media/images/card_bg_pattern@3x.png"
            )}')`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundColor: "white",
            alignItems: "center",
          }}
        >
          {/* begin::Body */}
          <div
            className="card card-custom shadow mb-5 card-preview"
            style={{
              backgroundImage: `url('${toAbsoluteUrl(
                "/media/images/card_template@2x.png"
              )}')`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundColor: "gainsboro",
            }}
          >
            <div className="card-body heading">
              <div className="balance-info">
                <span className="balance-title">Card Balance:</span>
                <h2 className="balance-text">{balance?.toFixed(2) + " pts" || ""}</h2>
              </div>
              <div className="reload" onClick={handleReloadClick}>
                <KTSVG
                  path="/media/icons/reload_icon.svg"
                  className="svg-icon-black"
                />
              </div>
            </div>
            <div className="card-body">{cardNum || ""}</div>
            <div className="card-footer">{btcAddr || btcAddress || ""}</div>
          </div>
          {/* end::Chart */}
        </div>
        {/* end: Card Body */}
      </div>
      {/* end::Card */}
    </>
  );
};

export { CardSection };
