import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { FallbackView } from "../../_start/partials";
import { ProfilePage } from "../pages/profile/Profile";
import { CardSearch } from "../pages/search/cardSearch";
import { useAccount } from 'wagmi';

export function PrivateRoutes() {
  const { isConnected } = useAccount();

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path="/profile" component={ProfilePage} />
        <Route path="/search" component={CardSearch} />
        {!isConnected && <Redirect from="/" to="/auth" />}
        {isConnected && <Redirect from="/" to="/search" />}
        <Redirect from="/auth/login" to={{ pathname: '/search'}} />
      </Switch>
    </Suspense>
  );
}