import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from "../_start/layout/core";
import { MasterLayout } from "../_start/layout/MasterLayout";
import { Logout } from "./modules/auth/Logout";
import { PrivateRoutes } from "./routing/PrivateRoutes";
import { PublicRoutes } from "./routing/PublicRoutes";
import { mainnet, optimism, polygon } from 'wagmi/chains';
import { WagmiConfig, createClient, configureChains, useAccount } from 'wagmi';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from '@wagmi/core/connectors/walletConnect';
import { publicProvider } from 'wagmi/providers/public';
import { getDefaultProvider } from "ethers";
import { CashierAddressControl } from "./helpers/CheckCashierControl/cashierAddressControl";
import { AuthPage } from "./modules/auth";
import { disconnect } from "@wagmi/core";
import { constants } from "./constants/constant";

const { webSocketProvider } = configureChains(
  [mainnet, optimism, polygon],
  [publicProvider()],
);

const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({
      chains: [polygon],
    }),
    new WalletConnectConnector({
      chains: [polygon],
      options: {
        projectId: constants.WalletConnectProjectId,
        showQrModal: true,
      },
    }),
  ],
  provider: getDefaultProvider(),
  webSocketProvider,
});

type Props = {
  basename: string;
};

const App: React.FC<Props> = ({ basename }) => {

  const { isConnected, address } = useAccount();
  const { isCashier, isLoading, cashiers } = CashierAddressControl();
  const savedCashierAddress = localStorage.getItem("authenticatedCashierAddress");

  useEffect(() => {
    if (isConnected && !isLoading && cashiers.length > 0 && address && !cashiers.includes(address.toLowerCase())) {
      disconnect();
    }
  }, [isConnected, isLoading, cashiers, address]);

  return (
    <WagmiConfig client={client}>
      <BrowserRouter basename={basename}>
        <ThemeProvider>
          <Switch>
            <Route path="/logout" component={Logout} />
            {!isCashier && !savedCashierAddress ? (
              <Switch>
                <Route path="/auth" component={AuthPage} />
                <Redirect from="/" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            ) :
              !isConnected ? (
                <Route>
                  <PublicRoutes />
                </Route>
              ) : (
                <>
                  <MasterLayout>
                    <PrivateRoutes />
                  </MasterLayout>
                </>
              )}
          </Switch>
        </ThemeProvider>
      </BrowserRouter>
    </WagmiConfig>
  );
};

export { App };
