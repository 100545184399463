import axios from "axios";
import { constants } from '../resources/constants';

const getMediaList = async () => {
    
    const options = {
        method: "get",
        url: constants.apiURL + "/getMediaList",
        headers: {
            'Content-Type': 'application/json',
        }
    };

    console.log('GetMediaList Request -> ', options);

    try{
        const response = await axios.request(options);  
        console.log('GetMediaList Response -> ', response);
        
        if(response.status !== 200){
            return null;
        }

        const resp_data = response.data;

        if(resp_data.code !== 0){
            return null;
        }
        
        return resp_data.data;
    }catch(error) {
        console.log(error);
        return null
    };
}

export default getMediaList;