export async function isValidCardNumber(input) {
    // Luhn algorithm implementation
  const digits = input.replace(/\D/g, '').split('').map(Number);
  let sum = 0;
  let shouldDouble = true;

  for (let i = digits.length - 1; i >= 0; i--) {
    let digit = digits[i];

    if (shouldDouble) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }

    sum += digit;
    shouldDouble = !shouldDouble;
  }

  const checkDigit = (Math.ceil(sum / 10) * 10 - sum) % 10;
  return checkDigit;

};
