/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Modal } from "react-bootstrap-v5";
import { toAbsoluteUrl } from "../../_start/helpers";

interface OperationData {
  operation_type: string;
  card_number: any;
  btc_address: any;
  prev_balance: any;
  input_amt: string;
  output_amt: any;
  exchange_rate: any;
  transaction_fee: any;
  date: string;
}

type Props = {
  show: boolean;
  operationData: OperationData;
  handleClose: () => void;
  sendTransaction: (outputAmount: number, inputAmount: number) => void;
};

const ConfirmModal: React.FC<Props> = ({ show, operationData, handleClose, sendTransaction}) => {

  const {
    operation_type,
    card_number,
    btc_address,
    prev_balance,
    input_amt,
    output_amt,
    exchange_rate,
    transaction_fee,
    date
  } = operationData;

  const [loading, setLoading] = useState(false);

  const confirmTransaction = async () => {
    setLoading(true);
    await sendTransaction(parseFloat(output_amt), parseFloat(input_amt));
    setLoading(false);
    handleClose();
  };

  return (
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog-centered mw-500px h-auto"
      show={show}
      onHide={handleClose}
    >
      <div className="container px-10 py-5">
        <div className="modal-header py-2 d-flex justify-content-end border-0">
          {/* begin::Close */}
          <div
            className="btn btn-icon btn-sm btn-close"
            onClick={handleClose}
          ></div>
          {/* end::Close */}
        </div>
        <div>
          <h1
            className="anchor fw-bolder mb-5 mt-5 justify-content-center"
            id="basic"
          >
            <a href="#basic"></a>Checkout
          </h1>

          <div className="modal-body space-y-20 pd-40 reviewModal listTab">
            <div className="hr"></div>
            <div className="d-flex justify-content-between">
              <p>Operation Type:</p>
              <p className="text-right price color-popup text-bold">
                {operation_type === "cash-withdraw"
                  ? "CASH-WITHDRAW"
                  : operation_type === "withdraw"
                  ? "CRYPTO-WITHDRAW"
                  : "DEPOSIT"}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p style={{ textAlign: "left" }}>Card Number:</p>
              <p
                className="text-right price color-popup text-bold"
                data-bs-placement="bottom"
              >
                {card_number}
              </p>
            </div>
            {!!btc_address && <div className="d-flex justify-content-between">
              <p style={{ textAlign: "left" }}>BTC Address:</p>
              <p
                className="text-right price color-popup text-bold"
                data-bs-placement="bottom"
              >
                {btc_address ? btc_address?.substr(0, 10) + "..." + btc_address?.substr(-10) : ''}
              </p>
            </div> }
            <div className="d-flex justify-content-between">
              <p style={{ textAlign: "left" }}>Prev Balance:</p>
              <p
                className="text-right price color-popup text-bold"
                data-bs-placement="bottom"
              >
                {prev_balance} pts
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p style={{ textAlign: "left" }}>Amount:</p>
              <p
                className="text-right price color-popup text-bold"
                data-bs-placement="bottom"
              >
                {input_amt}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p>Exchange Rate:</p>
              <p className="text-right price color-popup text-bold">{exchange_rate}</p>
            </div>
            <div className="d-flex justify-content-between">
              <p>Transaction Fee:</p>
              <p className="text-right price color-popup text-bold">{transaction_fee}</p>
            </div>
            <div className="d-flex justify-content-between">
                {operation_type === "deposit"
                  ? <p> You will deposit:</p>
                  : <p> You will withdraw:</p>
                 }
              <p className="text-right price color-popup text-bold"> {output_amt}</p>
            </div>
            <br />
            <div className="d-flex">
            <p>* Values were generated at <span className="text-bold-2">{date}</span><span>.</span></p>
            </div>

            <br />
            <div className="swap">
              {loading ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled
                  style={{ backgroundColor: "#007bff" }}
                >
                  <img
                    src={toAbsoluteUrl("/media/load.gif")}
                    className="loader"
                    alt="loading..."
                  />
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={confirmTransaction}
                  style={{ backgroundColor: "#007bff" }}
                >
                  Confirm Transaction
                </button>
              )}
            </div>
            <br />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { ConfirmModal };
