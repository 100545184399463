/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Modal } from "react-bootstrap-v5";
import { toAbsoluteUrl } from "../../_start/helpers";

type Props = {
  show: boolean;
  operation: string;
  handleClose: () => void;
};

const TransactionModal: React.FC<Props> = ({ show, operation, handleClose }) => {

  return (
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog-centered mw-500px h-auto"
      show={show}
      onHide={handleClose}
    >
    <div className="container px-10 py-10">
      <div className="modal-header py-2 d-flex justify-content-end border-0">
          {/* begin::Close */}
          <div
            className="btn btn-icon btn-sm btn-close"
            onClick={handleClose}
          >
          </div>
          {/* end::Close */}
        </div>
      <div>
        <h1 className="anchor fw-bolder mb-5 mt-5 justify-content-center" id="basic">
          <a href="#basic"></a>{operation} SUCCESSFUL!
        </h1>

        <div className="py-5 contact-text mb-5" style={{ justifyContent : 'center', display: 'flex' }}>
        <img
                  src={toAbsoluteUrl("/media/success.gif")}
                  className="success-gif justify-content-center"
                  alt="loading..."
                />
        </div>
       {/* <div className="input-group mb-10 receipt-btn-sec">

        <button
                className="btn text-white receipt-btn"
            >
               Print Receipt
  </button>

  </div>*/}
      </div>
      </div>
    </Modal>
  );
};

export { TransactionModal };
