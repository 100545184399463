import React from "react";
import { Footer } from "./components/Footer";
import { HeaderWrapper } from "./components/header/HeaderWrapper";
import { Toolbar } from "./components/Toolbar";
import { ScrollTop } from "./components/ScrollTop";
import { Content } from "./components/Content";
import { MasterInit } from "./MasterInit";
import { PageDataProvider } from "./core";

const MasterLayout: React.FC = ({ children }) => {
  return (
    <PageDataProvider>
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <HeaderWrapper />
            <div className="d-flex flex-column flex-column-fluid">
              <Content>{children}</Content>
            </div>
            <Footer />
          </div>
        </div>
      </div>
      <ScrollTop />
      <MasterInit />
    </PageDataProvider>
  );
};

export { MasterLayout };
