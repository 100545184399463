exports.constants = {
    apiURL: process.env.REACT_APP_SERVER_URL || 'https://icetm.kentkart.com/api',
    regexQR: /^\d{5}-\d{5}-\d$/,   // ex: 242331-93783-3
    bluetoothPrinterUID: '000018f0-0000-1000-8000-00805f9b34fb',
    bluetoothPrinterCharacteristicUID: '00002af1-0000-1000-8000-00805f9b34fb',
    bluetoothPrinterOptServiceUID: '00002900-0000-1000-8000-00805f9b34fb',
    withdrawMediaId: "643cf657107ffe149bb15e8d",
    withdrawProviderId: "643be86ada784ff54f32dac7",
    withdrawOpType: "withdraw",
    depositOpType: "deposit",
    btcAddressRegex: /^(bc1)[a-zA-HJ-NP-Z0-9]{42}$/gm,
    withdrawPayTypeBTC: "BTC",
    withdrawPayTypeCash: "USD",
    inputAmountMax: 10000000000
};
